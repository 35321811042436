<template>
    <div class="my flex">
        <div class="left">
            <div class="top">
                <div style="z-index:10;position: relative;">
                    <el-avatar :size="120" :src="$commit.getImg(info.avatar)">
                        <img src="shexie/img/portrait.png" style="height:auto;" />
                    </el-avatar>
                    <div style="margin:10px;">{{ info.name }}</div>
                    <div>绑定手机：{{ info.phone }}</div>
                    <div class="flex" v-if="info.rank" style="align-items: center;">
                        <img style="margin:10px;" :src="info.rank.icon" alt="">
                        <img class="xu" src="shexie/img/xu.png" alt="" @click="show = true, getrenew()">
                    </div>
                    <div class="cursor"
                        v-if="info.can_upgrade == true && info.rank.identifier != 'director' && info.director_status == false"
                        @click="$router.push('/leochIndex')">申请成为理事</div>
                </div>
                <img class="mys" src="shexie/img/mys.png" alt="">
            </div>
            <div class="bottom">
                <div v-for="(item, i) in myList" :key="i">
                    <div><img :src="item.img" alt="" ><span style="font-size:20px;">{{ item.name }}</span></div>
                    <div v-for="(items, i) in item.children" :key="i">
                        <div :class="{ active: items.path == $route.path }" v-if="items.show" class="li"
                            @click="$router.push(items.path)">{{ items.name }}</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="right"> 
            <router-view :info="info" @getInfo="getInfo"></router-view>
        </div>
        <div class="yingyin" v-if="show">
            <div class="yboxs">
                <div class="fx">
                    <div>支付</div>
                    <i class="el-icon-close cursor" @click="show = false, destoryInter()"></i>
                </div> 
                <div class="t-center" style="margin:30px 0px;" v-if="status == 1">
                    <div style="color:#CC0000;margin-bottom:10px;">支付会费{{ orderId.amount }}元</div>
                    <span>剩余支付时间：{{ interTime }}秒</span>
                    <div class="codeimg">
                        <div class="qrcode" ref="qrCodeUrl"></div>
                        <!-- <img :src="wechatCode.result.code_url" alt=""> -->
                    </div>
                    <img src="shexie/img/Scan_code.png" alt="">
                </div>
                <div class="t-center" v-if="status == 2" style="margin-top:60px;">
                    <img src="shexie/img/success.png" alt="">
                    <div style="margin:20px 0px;">
                        <div>您已成功支付会费</div>
                    </div>
                    <div class="btns" style="margin:auto;" @click="show = false">确定</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { users } from '@/api/login'
import { committee_renew } from '@/api/member'
import QRCode from 'qrcodejs2'
import { wechat_pay, payment_order } from '@/api/order'
import { member_apply } from '@/api/commit'
export default {
    data() {
        return {
            myList: [
                {
                    name: '账号管理', img: 'shexie/img/my_account.png', children: [
                        { name: '个人资料', path: '/my', show: true },
                        { name: '实名认证', path: '/identity', show: true },
                    ]
                },
                {
                    name: '会员专区', img: 'shexie/img/my_member.png', children: [
                        { name: '会员申请', path: '/member', show: true },
                        // {name:'邀请用户',path:'/Invite_users'},
                        { name: '会员单位申请', path: '/member_unit', show: true },
                        { name: '会员地图', path: '/member_map', show: true },
                        { name: '我的留言', path: '/feedback', show: true },
                        { name: '我的发票', path: '/invoice', show: true }
                    ]
                },
            ],
            info: {},
            wechatCode: {},
            status: 1,
            show: false,
            inter: null,

            interTime: 60,
            isSuccess: false,
            orderId: {},
        };
    },
    created() {
        this.getInfo()
        this.getmember_apply()
    },
    mounted() {

    },
    methods: {
        getmember_apply() {
            member_apply().then(res => {
                if (res.code == 200) {
                    if (res.data.apply_type == 1 && res.data.flag == true) {
                        this.myList[1].children[1].show = false
                    } else if (res.data.apply_type == 2 && res.data.flag == true) {
                        this.myList[1].children[0].show = false
                    }
                }
            })
        },
        getrenew() {
            committee_renew().then(res => {
                if (res.code == 200) {
                    this.orderId = res.data
                    this.getWechat()
                }
            })
        },
        getInfo() {
            users().then(res => {
                if (res.code == 200) {
                    this.info = res.data
                    localStorage.setItem('userid', this.info.id)
                    this.info.birthday = this.$commit.formatter(this.info.birthday).slice(0, 10)
                }
            })
        },
        getWechat() {
            wechat_pay(this.orderId.id, { from: 'pc' }).then(res => {
                if (res.code == 200) {
                    var qrcode = new QRCode(this.$refs.qrCodeUrl, {
                        text: res.data.result.code_url, // 需要转换为二维码的内容
                        width: 200,
                        height: 200,
                        colorDark: '#000000',
                        colorLight: '#ffffff',
                        correctLevel: QRCode.CorrectLevel.H
                    })
                    this.wechatCode = res.data
                    this.startInter()
                }
            })
        },
        //判断有没有支付完成
        startInter() {
            this.destoryInter();
            this.inter = setInterval(() => {
                --this.interTime;
                if (this.interTime % 2 == 0) {
                    this.orderCheck();
                }
                if (this.interTime < 1) {
                    this.destoryInter();
                    //支付时间已过;
                }
            }, 1000);
        },
        destoryInter() {
            if (this.inter) {
                clearInterval(this.inter);
                this.inter = null;
                this.interTime = 60;
                this.show = false
            }
        },
        //是否成功
        orderCheck() {
            payment_order({ order_sn: this.wechatCode.order_sn }).then(res => {
                if (res.code == 200) {
                    this.$message.success("支付成功!");
                    this.isSuccess = true;
                    this.status = 2
                    clearInterval(this.inter);
                }
            })
        },
    }
};
</script>

<style scoped lang="scss">
::v-deep .el-avatar>img {
    width: 100% !important;
}

.my {
    width: 1200px;
    margin: 40px auto;

    .left {
        margin-right: 30px;

        .top {
            width: 280px;
            height: 280px;
            background: #FFFFFF;
            border-radius: 4px;
            text-align: center;
            padding-top: 40px;
            position: relative;

            .mys {
                position: absolute;
                bottom: 0;
                left: 0;
            }

            .cursor {
                color: #CC0000;
                border-bottom: 1px solid #CC0000;
                display: inline-block;
            }

            .xu {
                width: 41px;
                // height: 24px;
                position: relative;
                left: -5px;
                cursor: pointer;
            }
        }

        .bottom {
            margin-top: 20px;
            background: #fff;
            padding: 20px;

            img {
                margin-right: 10px;
            }

            .li {
                padding: 5px 30px;
                margin: 10px 0px;
                cursor: pointer;
            }

            .active {
                background: #FFF5F5;
                color: #CC0000;
            }
        }
    }

    .right {
        width: 100%;
        padding: 20px;
        background: #fff;
    }

    .yingyin {
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        background: rgba(1, 1, 1, .4);
        z-index: 99;

        .yboxs {
            width: 480px;
            height: 474px;
            background: #FFFFFF;
            border-radius: 10px;

            .fx {
                padding: 10px 30px;
                background: #F5F5F5;

                .cursor {
                    font-size: 24px;
                }
            }

            .codeimg {
                margin: 20px 0px;

                img {
                    padding: 10px;
                    border: 1px solid #ccc;
                    width: 200px;
                    height: 200px;
                }

                .qrcode {
                    padding: 10px;
                    border: 1px solid #ccc;
                    width: 200px;
                    height: 200px;
                    margin: auto;
                }
            }

            .btns {
                width: 200px;
                height: 48px;
                color: #fff;
                text-align: center;
                line-height: 48px;
                background: #CC0000;
                border-radius: 24px;
                cursor: pointer;
            }
        }
    }
}</style>
