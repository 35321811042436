import service from '@/utils/request'

//会员列表
export function memberList(){
    return service({
        url:'api/member_order/1',
        method:'get',
    })
}
//会员申请
export function member_apply(data){
    return service({
        url:'api/member_apply',
        method:'post',
        data
    })
}
//理事申请
export function leoch_apply(data){
    return service({
        url:'web/v1/committee-applies/upgrade',
        method:'post',
        data
    })
}

//修改
export function committee_edit(data,id){
    return service({
        url:'api/member_apply/'+id,
        method:'put',
        data
    })
}
//详情
export function committee_info(id){
    return service({
        url:'api/member_apply/'+id,
        method:'get',
    })
}
//续费
export function committee_renew(){
    return service({
        url:'api/member_order/renew',
        method:'get',
    })
}