import service from '@/utils/request'

//验证码
export function codes(data){
    return service({
        url:'api/sms',
        method:'post',
        data
    })
}
//轮播图
export function advs(){
    return service({
        url:'api/ads/1',
        method:'get',
    })
}
//七牛上传
export function qiniu_upload(data){
    return service({
        url:'api/file/upload',
        method:'post',
        data
    })
}
//说明
export function explain(type){
    return service({
        url:'api/rank/rights/'+type,
        method:'get',
    })
}
//获取地址
export function areas(id){
    return service({
        url:'api/area/list?pid='+id,
        method:'get',
    })
}
//获取会员城市分布
export function city_user(){
    return service({
        url:'api/member/province-count',
        method:'get',
    })
}

//获取用户是否已申请
export function member_apply(){
    return service({
        url:'api/member/apply',
        method:'get',
    })
}
